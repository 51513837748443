import React from "react";
import "../assets/css/Contact.css";
import blackTelImg from "../assets/images/blackTelImg.png";

const Contact = () => {
  return (
    <div id="contact" className="reveal">
      <div className="contactFlex">
        <div className="description">
          <div className="pContainer">
            <h2 className="contactTitle">İletişim</h2>
            <h5>
              <b>Bizimle İletişime Geçin</b>
            </h5>
            <p>
              <span className="contactSpan">Valesepeti</span> hakkında daha
              detaylı bilgi almak, görüş, öneri ve şikayet bildirmek için
              bizimle yandaki formu kullanarak iletişime geçebilirsiniz!
            </p>
            <div className="telFlex">
              <a className="blackTelA" href="tel:05368942157">
                <img className="blackTelImg" src={blackTelImg} alt="" />
                0545 155 34 59
              </a>
              <a className="blackTelA" href="tel:05446470187">
                <img className="blackTelImg" src={blackTelImg} alt="" />
                0544 647 01 87
              </a>
            </div>
          </div>
        </div>
        <div className="form">
          <div className="formContainer">
            <form action="">
              <p>
                <b>Adınız Soyadınız</b>
              </p>
              <input type="text" name="" id="" />
              <p>
                <b>E-posta Adresiniz</b>
              </p>
              <input type="email" name="" id="" />
              <p>
                <b>Telefon Numaranız</b>
              </p>
              <input type="phone" name="" id="" />
              <p>
                <b>Mesajınız</b>
              </p>
              <textarea name="" id=""></textarea>
              <button type="submit" className="submit">
                Gönder
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
