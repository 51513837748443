import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../assets/css/Navbar.css";
import telImg from "../assets/images/telImg.png";

import logo from '../assets/images/logo.png';

const NavbarComponent = () => {
  const [innerWidth, setInnerWidth] = useState();
  const [navBackground, setNavBackground] = useState("appBarTransparent");
  const navRef = React.useRef();
  navRef.current = navBackground;

  const navigate = useNavigate();
  const { hash, pathname } = useLocation();

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 300;

      if (show) {
        setNavBackground("appBarTransparent");
      } else {
        setNavBackground("appBarSolid");
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // MOBILE NAVBAR
  if (innerWidth && innerWidth < 768) {
    return (
      <div className="mobileHeader">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand
            href="#home"
            style={{
              display: "flex",
            }}
            onClick={() => navigate("/#home")}
          >
            <img className="valeSepetiMobile" src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link
                className="linkMobile"
                href="#home"
                onClick={() => navigate("/#home")}
              >
                Anasayfa
              </Nav.Link>

              <Nav.Link
                className="linkMobile"
                href="#who"
                onClick={() => navigate("/#who")}
              >
                Biz Kimiz?
              </Nav.Link>

              <Nav.Link
                className="linkMobile"
                href="#why"
                onClick={() => navigate("/#why")}
              >
                Neden Vale Sepeti?
              </Nav.Link>

            

              <Nav.Link
                className="linkMobile"
                href="#sss"
                onClick={() => navigate("/#sss")}
              >
                SSS
              </Nav.Link>

              <Nav.Link
                className="linkMobile"
                href="#contact"
                onClick={() => navigate("/#contact")}
              >
                İletişim
              </Nav.Link>

              <div className="linkMobile">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <a className="linkMobile" href="tel:05368942157">
                      <img
                        className="linkMobile"
                        style={{ marginRight: "1rem" }}
                        src={telImg}
                        alt="telImg"
                      />
                      0545 155 34 59
                    </a>
                  </div>
                  <div>
                    <a className="linkMobile" href="tel:05446470187">
                      <img
                        className="linkMobile"
                        style={{ marginRight: "1rem" }}
                        src={telImg}
                        alt="telImg"
                      />
                      0544 647 01 87
                    </a>
                  </div>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }

  // WEB NAVBAR
  else {
    return (
      <div className={navRef.current}>
        <div>
           <img onClick={() => navigate("/#home")} src={logo} alt="" style={{height: 75, paddingTop: 10, paddingBottom: 10 }} />

        </div>

        <ul>
          <li style={{ paddingTop: ".5rem" }}>
            <a
              className={
                hash === "#home" || (hash === "" && pathname === "/")
                  ? "link active navLink"
                  : "link navLink"
              }
              href="#home"
              onClick={() => navigate("/#home")}
            >
              Anasayfa
            </a>
          </li>
          <li style={{ paddingTop: ".5rem" }}>
            <a
              className={
                hash === "#who" ? "link active navLink" : "link navLink"
              }
              href="#who"
              onClick={() => navigate("/#who")}
            >
              Biz Kimiz?
            </a>
          </li>
          <li style={{ paddingTop: ".5rem" }}>
            <a
              className={
                hash === "#why" ? "link active navLink" : "link navLink"
              }
              href="#why"
              onClick={() => navigate("/#why")}
            >
              Neden Valesepeti?
            </a>
          </li>
         
          <li style={{ paddingTop: ".5rem" }}>
            <a
              className={
                hash === "#sss" ? "link active navLink" : "link navLink"
              }
              href="#sss"
              onClick={() => navigate("/#sss")}
            >
              SSS
            </a>
          </li>
          <li style={{ paddingTop: ".5rem" }}>
            <a
              className={
                hash === "#contact" ? "link active navLink" : "link navLink"
              }
              href="#contact"
              onClick={() => navigate("/#contact")}
            >
              İletişim
            </a>
          </li>
          <li>
            <div className="telLinkContainer">
              <div>
                <a className="link" href="tel:05368942157">
                  <img className="telLink" src={telImg} alt="telImg" />
                  0545 155 34 59
                </a>
              </div>
              <div>
                <a className="link" href="tel:05446470187">
                  <img className="telLink" src={telImg} alt="telImg" />
                  0544 647 01 87
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }
};
export default NavbarComponent;
