import React, { useEffect, useState } from "react";
import "../assets/css/Home.css";
import home from "../assets/images/home.png";
import homeMobile from "../assets/images/homeMobile.png";
import Contact from "./Contact";
import Sss from "./Sss";
import Who from "./Who";
import Why from "./Why";

const Home = () => {
  const [innerWidth, setInnerWidth] = useState();

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  }, []);

  // MOBILE NAVBAR
  if (innerWidth && innerWidth < 768) {
    return (
      <>
        <div id="home" className="home reveal">
          <img className="homeImgMobile" src={homeMobile} alt="" />
          {/* <Link to="/comingSoon">
            <img className="appStoreMobile" src={appStore} alt="" />
          </Link>
          <Link to="/comingSoon">
            <img className="googlePlayMobile" src={googlePlay} alt="" />
          </Link> */}
        </div>
        <Who />
        <Why />
        {/* <How /> */}
        <Sss />
        <Contact />
      </>
    );
  }

  // WEB NAVBAR
  else {
    return (
      <>
        <div id="home" className="home reveal">
          <img className="homeImg" src={home} alt="" />
          {/* <Link to="/comingSoon">
            <img className="appStore" src={appStore} alt="" />
          </Link>
          <Link to="/comingSoon">
            <img className="googlePlay" src={googlePlay} alt="" />
          </Link> */}
        </div>
        <Who />
        <Why />
        {/* <How /> */}
        <Sss />
        <Contact />
      </>
    );
  }
};

export default Home;
